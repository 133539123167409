.HexList-actions, .HexList-name {
  display: flex;
  align-items: center;
  gap: 8px;
}
.HexList-image {
  max-width: 60px;
}
.HexList-topActions {
  display: flex;
  justify-content: space-between;
}
.HexList-add, .HexList-return {
  padding: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0 0 8px 0;
}
.HexList {
  display: flex;
  flex-direction: column;
}