.TableForm-row {
  display: flex;
  column-gap: 20px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  .TableForm-rowNumber {
    flex-basis: 120px;
    text-align: right;
  }

  .TableForm-rowName {
    flex-grow: 1;
  }

  .TableForm-SettingsButton {
    margin-top: 10px;
  }

  .TableForm-Settings {
    flex-basis: 100%;
  }
}

.EditTableRow-ShowProps {
  flex-basis: 100%;
}