.TableForm-fields {
  display: grid;
  grid-template-columns: 120px auto;
  column-gap: 20px;
  align-items: center;

  & > label {
    text-align: right;
  }
}
.TableForm-value {
  margin-top: 8px;
  max-width: 200px;
}
.EditTableRows {
  grid-column: span 2;
  padding: 0;
}
.TableForm-rows {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.PropsHeader {
  display: inline-flex;
  align-items: center;
}
.ErrorText {
  display: inline-block;
  color: red;
}