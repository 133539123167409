.configOption {
  display: flex;
  flex-direction: column;
  align-items: normal;
  gap: 8px;

  label {
    color: white;
  }

  & > div {
    margin-bottom: 16px;
  }
}