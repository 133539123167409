.RandomTable-item {
  background-color: white;
  
  width: 200px;
  height: 140px;
  border-radius: 8px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #BBB;
  margin: -1px;
  position: relative;

  @media only screen and (max-width: 480px) {
		width: 100%;
    flex-direction: row;
	}

  .RandomTable-name {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 0.95rem;

    @media only screen and (max-width: 480px) {
      font-size: 1.2rem;
    }
  }

  .RandomTable-source {
    flex-grow: 1;
    width: 100%;
    font-size: 0.8rem;

    @media only screen and (max-width: 480px) {
      font-size: 1rem;
    }
  }

  .RandomTable-actions {
    flex-grow: 1;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    width: 100%;

    .IconButton {
      flex-grow: 1;
      justify-content: center;

      .RandomTable-playMode & {
        justify-content: flex-end;
      }
    }

    @media only screen and (max-width: 480px) {
      width: unset;
      flex-direction: row-reverse;
      display: grid;
      grid-template-columns: auto auto;
    }

    .RandomTable-dice {
      img {        
        height: 40px;
      }
      @media only screen and (max-width: 480px) {
        grid-column: 2 span;
        img {        
          height: 60px;
        }
      }
    }
  }
}

.RandomTable-encounter {
  background-color: #f5e8ea;
}
.RandomTable-exploration {
  background-color: #ecf0e9;
}
.RandomTable-loot {
  background-color: #fbf5e8;
}
.RandomTable-npc {
  background-color: #f0edfb;
}
.RandomTable-weather {
  background-color: #e5eaf4;
}