:root {
  --bar-width: 180px;
}
.SideBar {
  padding: 60px 20px;
  display: flex;
  flex-direction: column;
  width: var(--bar-width);
  font-size: 1.2rem;
  background-color: #757d83;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  overflow: auto;
  z-index: 2;
  transition: all 0.25s;

  @media only screen and (max-width: 480px) {
    right: 0;
    width: unset;
	}

  &.SideBar--hidden {
    left: calc(-1 * var(--bar-width));

    @media only screen and (max-width: 480px) {
      left: -100vw;
      right: 100vw;
    }
  }

  &.SideBar--right {
    right: 0;
    left: unset;
  
    @media only screen and (max-width: 480px) {
      left: 0;
      width: unset;
    }

    &.SideBar--hidden {
      right: calc(-1 * var(--bar-width));
  
      @media only screen and (max-width: 480px) {
        left: 100vw;
        right: -100vw;
      }
    }
  }
}

button.SideBar-close {
  display: inherit;
  position: absolute;
  top: 0;
  left: 0;
  margin: 20px;

  .SideBar--right & {
    left: unset;
    right: 0;
  }
}