.resultName {
  margin: 0;
  margin-right: 8px;
  font-weight: bold;
  display: inline-block;
  padding-left: 16px;
}

.resultValue {
  display: inline-block;
}