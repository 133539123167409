.RandomTable-list {
  list-style: none;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  padding-left: 20px;

  @media only screen and (max-width: 480px) {
		padding-left: 0;
	}
}

.RandomTable-controlBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.RandomTable-massActions {
  display: flex;
  align-items: center;

  button {
    display: flex;
    align-items: center;
    margin: 0 4px;
  }
}

.RandomTables-editToggle {
  display: flex;
  align-items: center;
  gap: 8px;
  @media only screen and (max-width: 480px) {
    display: none;
  }
}

.RandomTable-add-item {
  font-size: 3rem;
  border-style: dashed;
  cursor: pointer;
}

.DesktopOnly {
  @media only screen and (max-width: 480px) {
    display: none;
  }
}

.RandomTables-example {
  display: flex;
  align-items: center;
  gap: 100px;
  flex-wrap: wrap;

  @media only screen and (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
}