body {
  margin: 0;
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input, textarea {
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

input[type='text'], input[type='number'] {
  border-radius: 6px;
  border: 1px solid rgb(216, 219, 224);
  line-height: 24px;
  padding: 6px 12px;
  margin-top: 8px;
  font-size: 0.875rem;
}

input[type='button'], input[type='submit'], button {
  border-radius: 6px;
  border: 1px solid rgb(216, 219, 224);
  line-height: 24px;
  padding: 6px 12px;
  cursor: pointer;
  font-size: 0.875rem;
  margin: 4px;
  color: rgb(13, 26, 38);
}

textarea {
  border-radius: 6px;
  border: 1px solid rgb(216, 219, 224);
  line-height: 24px;
  padding: 6px 12px;
  margin-top: 8px;
}

input[type='number'] {
  width: 65px;
}

a {
  text-decoration: none;
  color: rgb(0, 126, 185)
}