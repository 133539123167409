.PropTable {
  margin-left: 140px;
  margin-top: 10px;
  display: grid;
  grid-template-columns: 170px auto 200px max-content;
  column-gap: 10px;
  row-gap: 4px;

  input[type='text'] {
    margin-top: 0;
    width: 100%;
    box-sizing: border-box;
  }
}

.DisplayInput {
  grid-column: span 2;
  margin: 0;
}