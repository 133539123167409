$image-size: 150px;
.About-profilePic {
  width: $image-size;
  border-radius: $image-size;
  float: left;
  margin: 20px 20px 0 0;
}

.About-siteIcon {
  width: $image-size;
  float: right;
  margin: 20px 0 0 20px;
}

.About-patreonLogo {
  width: $image-size;
  float: left;
  margin: 20px 20px 0 0;
}