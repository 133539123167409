.NarrateAI-header {
  display: flex;
  align-items: center;
}

.NarrateAI-body {
  display: flex;
  flex-direction: column;
}

.NarrateAI-guest img {
  display: block;
}

.NarrateAI-sidebar-cta {
  color: white;
}