.App {
  height: 100vh;
  width: 100vw;
  overflow: auto;
  background-color: #E5E5E5;
}

.App-body {
  flex-grow: 1;
  padding: 140px 20px 120px 20px;
  max-width: 1148px;
  min-height: 100vh;
  margin: 0 auto;
  position: relative;
}

img.Login-Logo {
  max-width: 300px;
}