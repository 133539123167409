.HexForm-fields {
  display: grid;
  grid-template-columns: 140px auto;
  column-gap: 20px;
  align-items: center;
  margin-bottom: 20px;

  & > label {
    text-align: right;
  }
}
.HexForm-value {
  margin-top: 8px;
  display: flex;
  align-items: center;
}
.HexForm-image {
  max-width: 71px;
}
.HexForm-dropdown {
  min-width: 300px;
}