$header-footer-color: #fcfcfc;

.FixedModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  border-radius: 4px;
  outline: none;
  @media only screen and (max-width: 480px) {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: none;
    display: flex;
    flex-direction: column;
  }
}
.FixedModal-overlay {
  position: fixed;
  inset: 0px;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 100;
}
.ModalHeader {
  background-color: $header-footer-color;
  border-bottom: 1px solid #eee;
  padding: 12px 20px;
  display: flex;
  align-items: center;

  h2 {
    margin: 0;
    font-size: 1.2rem;
  }

  .CloseButton {
    flex-grow: 1;
    text-align: right;
  }
}
.ModalBody {
  max-height: 65vh;
  overflow: auto;
  padding: 20px;

  @media only screen and (max-width: 480px) {
    flex-grow: 1;
    max-height: none;
  }
}
.ModalFooter {
  background-color: $header-footer-color;
  border-top: 1px solid #eee;
  padding: 8px;
  text-align: right;
}