.NavBar-header {
  position: fixed;
  top: 0;
  z-index: 2;
  width: calc(100% + (100vw - 100%));;
  margin: 0;
  border-radius: 0;
  padding: 20px;
  background-color: #E5E5E5;
  display: flex;
}

.NavBar-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1148px;
  margin: 0 auto;
  flex-grow: 1;

  @media only screen and (max-width: 480px) {
    flex-grow: 0;
  }

  .BagIcon {
    height: 80px;
    margin: 0;
  }

}

.NavBar-logo {
  width: 100%;
  pointer-events: none;
  border-radius: 10px;

  @media only screen and (max-width: 480px) {
    width: 50%;
  }
}

.NavBar-logoLink {
  @media only screen and (max-width: 480px) {
    text-align: center;
  }
}

.NavBar-links {
  @media only screen and (max-width: 480px) {
    display: none;
  }
}
button.NavBar-toggle {
  position: absolute;
  top: 0;
  right: 0;
  margin: 20px;
  
  @media only screen and (min-width: 480px) {
    display: none;
  }
}
.NavBar-AccountInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.9rem;
  padding: 4px;

  & > p {
    color: white;
    font-size: 1.1rem;
    // margin: 0;
  }

  & > button {
    width: 90%;
  }
}
.NavBar-sidebar button[role=tab] {
  color: white;
}
.NavBar-toolsTab {
  display: flex;
  align-items: center;

  svg {
    padding-left: 4px;
  }
}
.NavBar-donate {
  
}