.HexExplorer-header {
  display: flex;
  align-items: center;
}

.HexExplorer-sidebar {
  padding: 40px 20px;

  .filterLabel {
    color: white;
  }
}