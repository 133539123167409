.RandomTable-filters {
  display: inline-flex;
  align-items: center;
  gap: 16px;
  padding: 0px;
  flex-wrap: wrap;
  z-index: 1;

  & > div {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 8px;
  }
}

.RandomTable-filterButton {
  z-index: 1;
}

.RandomTable-filterValue {
  display: inline-block;
}

input.RandomTable-filterValue {
  margin: 0;
}

.RandomTable-sidebarFilters {
  & > div {
    margin: 4px;
  }

  .filterLabel {
    color: white;
  }
}