.ToggleGroup {
  display: flex;
  flex-wrap: nowrap;
}
button.ToggleGroup-button {
  margin: 0;
  border-radius: 0;
  background-color: white;

  &:first-of-type {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  &:last-of-type {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  &.active {
    background-color: #CCC;
  }
}