.HomeItem {
  width: 100%;
  display: grid;
  column-gap: 20px;
  margin-bottom: 20px;
  align-items: center;
  justify-content: start;

  img {
    grid-area: icon;
    width: 150px;
  }
  h4 {
    grid-area: title;
  }
  p {
    grid-area: desc;
    margin: 0;
    align-self: start;
  }

  &.HomeItem--left {
    grid-template: 
              "icon title" max-content
              "icon desc" 
              / 150px 1fr;
  }

  &.HomeItem--right {
    grid-template: 
              "title icon" max-content
              "desc icon" 
              / 1fr 150px;
    
    text-align: right;
  }
}
.Home-link {
  padding: 0;
}