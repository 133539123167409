.App-footer {
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
}
.Footer-linkList {
  display: flex;
  gap: 20px;
  font-size: 0.8rem;
  justify-content: center;
}