.EditTableRows-AddRow {
  text-align: right;

  input {
    margin-right: 0;
    margin-top: 8px;
  }
}

.EditTableRows-Options {
  text-align: right;
}
.EditTableRows-Mode {
  display: inline-block;
}
.EditTableRows-Odds {
  display: inline-block;
}